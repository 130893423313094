import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company
            address
            zip
            country
            city
            number
            email
          }
        }
      }
    `
  );
  const { company, address, zip, country, city, number, email } =
    site.siteMetadata;
  return (
    <div className="footer__contact">
      <ul className="footer__rax-address">
        <li>{company}</li>
        <li>{address}</li>
        <li>{`${zip} ${city}, ${country}`}</li>
        <div className="footer__rax-tel">
          <a href="/#">
            <FontAwesomeIcon icon={faPhone} /> <span>{number}</span>
          </a>
        </div>
        <div className="footer__rax-mail">
          <a href="/#">
            <FontAwesomeIcon icon={faEnvelopeOpen} /> <span>{email}</span>
          </a>
        </div>
      </ul>
    </div>
  );
};

export default Footer;
