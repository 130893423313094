import React from "react";
import Logo from "./logo";
import Navigation from "./navigation";
import SocialMedia from "./socialMedia";
import Contact from "./contact";
import Copyright from "./copyright";
import PrivacyPolicy from "./privacyPolicy";

const Footer = () => {
  return (
    <>
      <footer data-nosnippet className="footer container-fluid">
        <div className=" footer__row row">
          <div className="footer__column col-md center-block">
            <Logo />
          </div>
          <div className="footer__column col-md center-block">
            <Navigation />
          </div>
          <div className="footer__column col-md center-block">
            <SocialMedia />
          </div>
          <div className="footer__column col-md center-block">
            <Contact />
          </div>
        </div>
        <div className="footer__line"></div>
        <div className="row">
          <div className="col">
            <Copyright />
          </div>
          <div className="col">
            <address className="footer__policy columns">
              <PrivacyPolicy />
            </address>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
