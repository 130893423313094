import React from "react";
import AppProvider from "./src/hooks";
import { BaseLayout } from "./src/layouts/BaseLayout";

const wrapPageElement = ({ element, props }) => (
  <AppProvider>
    <BaseLayout
      className="container-fluid text-center lato-loaded muli-loaded"
      {...props}
    >
      {element}
    </BaseLayout>
  </AppProvider>
);

export default wrapPageElement;
