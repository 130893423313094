import React from "react";
import LocalizedLink from "../LocalizedLink";

const NavLinks = (props) => {
  return (
    <>
      {!props.exclude && (
        <li>
          <LocalizedLink
            to={props.link}
            activeClassName="active"
            aria-label={props.name}
            className={`${props.className}`}
          >
            {props.name}
          </LocalizedLink>
        </li>
      )}
    </>
  );
};

export default NavLinks;
