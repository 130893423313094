import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const Copyright = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            copyright
          }
        }
      }
    `
  );
  const { copyright } = site.siteMetadata;
  return (
    <div className="footer__subfooter columns">
      <div className="footer__copyright">
        <span>
          <FontAwesomeIcon icon={faCopyright} /> {copyright}{" "}
        </span>
      </div>
    </div>
  );
};

export default Copyright;
