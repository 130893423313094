import React from "react";
import useOtherLocations from "../useOtherLocations";
import LocalizedLink from "../LocalizedLink";

const PrivacyPolicy = () => {
  const items = useOtherLocations();
  const button = items.filter((item) => item.name === "privacy_policy")[0];
  return (
    <>
      {!button.exclude && (
        <LocalizedLink to={button.link} aria-label={button.name}>
          {button.title}
        </LocalizedLink>
      )}
    </>
  );
};

export default PrivacyPolicy;
