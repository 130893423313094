import React from "react";
import { navigate, Link } from "gatsby";
// import { useStaticQuery, graphql } from "gatsby";
import { useLocale } from "../../../hooks/locale";
import { useMenu } from "../../../hooks/menu";
// import useLanguageMapping from "../../useLanguageMapping";
// import usePrefixMapping from "../../usePrefixMapping";
const locales = require(`../../../../config/i18n`);

// const mergePath = (array, locale, prefixMapping) => {
//   let path = "";
//   array.forEach((item, index) => {
//     const isPrefix = prefixMapping.find((item) => {
//       let isPrefix = false;
//       Object.entries(item).forEach(([key, value]) => {
//         if (value === item) {
//           path += `/${item[locale]}`;
//           return (isPrefix = true);
//         }
//       });

//       return isPrefix;
//     });

//     if (!isPrefix)
//       path += index !== 0 && item !== "en" && item !== "pl" ? `/${item}` : "";
//   });
//   return path;
// };

const Languages = () => {
  const { locale } = useLocale();
  const { openedLang, toggleLang } = useMenu();
  // const { allSitePage } = useStaticQuery(query);
  // const prefixMapping = usePrefixMapping();
  // const languageMapping = useLanguageMapping();

  function handleClickLanguage(e, lang) {
    e.preventDefault();
    if (locale === lang) return;

    if (locales[lang].exclude) return navigate(locales[lang].host); //if lang is exclude, redirect to main page

    if (window.location.pathname.includes("blog")) return null;

    //remake!!! multidomain
    // const url = window.location.pathname.split("/").pop();

    // if (!url)
    //   return lang === locales[lang].default
    //     ? navigate(`/`)
    //     : navigate(`/${lang}`);

    // // if (url === "en") {
    // //   return lang === locales[lang].default ? navigate(`/`) : navigate(`/${lang}`);
    // // }

    // const associatedUrls = languageMapping.find((item) => {
    //   let hasUrl = false;

    //   Object.entries(item).forEach(([key, value]) => {
    //     if (value && value.split("/").pop() === url) return (hasUrl = true);
    //   });

    //   return hasUrl;
    // });

    // if (!associatedUrls) {
    //   let hasUrl = false;
    //   let path = mergePath(
    //     window.location.pathname.split("/"),
    //     locale,
    //     prefixMapping
    //   );
    //   path = lang === locales[lang].default ? `${path}` : `/${lang}${path}`;

    //   allSitePage.edges.forEach(({ node }) => {
    //     console.log(node.path === path);
    //     if (node.path === path) return (hasUrl = true);
    //   });

    //   if (hasUrl) {
    //     return navigate(path);
    //   } else {
    //     return lang === locales[lang].default
    //       ? navigate(`/`)
    //       : navigate(`/${lang}`);
    //   }
    // }

    // return lang === locales[lang].default
    //   ? navigate(`${associatedUrls[lang]}`)
    //   : navigate(`/${lang}${associatedUrls[lang]}`);
  }

  const handleClick = () => {
    toggleLang(!openedLang);
  };

  return (
    <div className="header__lang">
      <div
        role="button"
        onKeyDown={handleClick}
        className="lang__btn"
        tabIndex={0}
        onClick={handleClick}
      >
        {locale.toUpperCase()}
      </div>
      <div
        className="lang__content"
        style={{ display: openedLang ? "block" : "none" }}
      >
        <Link
          to="/"
          onClick={(e) => handleClickLanguage(e, "pl")}
          className="lang__option"
        >
          PL
        </Link>
        <Link
          to="/"
          onClick={(e) => handleClickLanguage(e, "en")}
          className="lang__option"
        >
          EN
        </Link>
        {/* <Link to="/" onClick={(e) => handleClickLanguage(e, "de")} className="lang__option">
          DE
        </Link> */}
      </div>
    </div>
  );
};

export default Languages;

// const query = graphql`
//   query language {
//     allSitePage {
//       edges {
//         node {
//           path
//         }
//       }
//     }
//   }
// `;
