module.exports = {
  pl: {
    path: `pl`,
    locale: `pl-PL`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `pl`,
    ogLanguage: `pl_PL`,
    defaultTitle: `RAX`,
    defaultDescription: `Systemy, strony i aplikacje trafiające w sedno`,
    exclude: true,
    host: "https://rax.com.pl",
    keywords: ["rax", "it"],
  },
  en: {
    default: true,
    path: `en`,
    locale: `en-EN`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_EN`,
    defaultTitle: `RAX`,
    defaultDescription: `Software for efficiency | Custom software | Deployments| Services| Consulting - RAX`,
    host: "https://rax.dev",
    keywords: ["rax", "it"],
  },
};
