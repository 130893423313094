import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const MenuContext = createContext({});

const MenuProvider = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [openedLang, setOpenedLang] = useState(null);
  const [resultSearch, setResults] = useState(null);
  const [openedSearch, setOpenedSearch] = useState(null);

  function toggleMenu(value) {
    setOpenedMenu(value);
  }

  function setSearch(results) {
    setResults(results);
  }

  function toggleLang(value) {
    setOpenedLang(value);
  }

  function toggleSearch(value) {
    setOpenedSearch(value);
  }

  return (
    <MenuContext.Provider
      value={{
        openedMenu,
        toggleMenu,
        resultSearch,
        setSearch,
        toggleLang,
        openedLang,
        openedSearch,
        toggleSearch,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenu must be used within an MenuProvider");
  }
  return context;
};

export { MenuProvider, useMenu };
