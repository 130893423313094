import React, { useState, useEffect } from "react";
import Navbar from "../Navigation/navbar";
import { useMenu } from "../../hooks/menu";

const Header = () => {
  const [lastScrollValue, setLastScrollValue] = useState(0);
  const [isSticky, setSticky] = useState(false);
  const [isShadow, setShadow] = useState(false);
  const [currentClass, setCurrentClass] = useState("");
  const [isMobile, setMobileNav] = useState(false);
  const { openedMenu, toggleMenu } = useMenu();

  function handleScroll() {
    if (window.scrollY > window.innerHeight) {
      if (lastScrollValue > window.scrollY) {
        setSticky(true);
      } else {
        setSticky(false);
      }
      setShadow(true);
    } else {
      setSticky(false);
      setShadow(false);
    }
    setLastScrollValue(window.scrollY);
  }

  const handleMobileNav = () => {
    if (isMobile) {
      if (openedMenu) {
        setCurrentClass("slide-down");
      } else setCurrentClass("slide-up");
    } else {
      setCurrentClass("");
    }
  };

  const handleResize = () => {
    if (1069 >= window.innerWidth) {
      setMobileNav(true);
      handleMobileNav();
    } else {
      setMobileNav(false);
      setCurrentClass("");
      toggleMenu(false);
    }
  };

  useEffect(() => {
    function watchScroll() {
      setMobileNav(1069 >= window.innerWidth);
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
    }
    watchScroll();
    handleMobileNav();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <header
      data-nosnippet
      className={`${isSticky ? "sticky" : ""} header ${currentClass}`}
      style={
        isShadow
          ? {
              boxShadow:
                "0 5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)",
            }
          : {}
      }
    >
      <Navbar />
    </header>
  );
};

export default Header;
