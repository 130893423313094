import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faSkype } from "@fortawesome/free-brands-svg-icons";

const SocialMedia = () => {
  return (
    <div className="footer__social-media">
      <li className="list-inline-item icon">
        <a href="/#">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </li>
      <li className="list-inline-item icon">
        <a href="/#">
          <FontAwesomeIcon icon={faSkype} />
        </a>
      </li>
    </div>
  );
};

export default SocialMedia;
