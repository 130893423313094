import React from "react";
import { useMenu } from "../../hooks/menu";

const NavbarToggle = () => {
  const { openedMenu, toggleMenu } = useMenu();

  const handleToggleMenu = () => {
    toggleMenu(!openedMenu);
  };

  return (
    <div
      className={`header__hamburger-icon ${
        openedMenu ? "is-active checked" : ""
      }`}
      onClick={handleToggleMenu}
      onKeyUp={() => {
        return null;
      }}
      role="button"
      tabIndex={0}
    >
      <span className="hamburger-icon"></span>
    </div>
  );
};

export default NavbarToggle;
