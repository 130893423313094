import { useLocale } from "../hooks/locale";

function useTranslations(rawData) {
  const { locale } = useLocale();

  const simplified = rawData.edges.map((item) => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    };
  });

  const { translations } = simplified.filter((lang) => lang.name === locale)[0];
  return translations;
}

export default useTranslations;
