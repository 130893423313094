import React, { useEffect } from "react";
import { useLocale } from "../hooks/locale";
import { useMenu } from "../hooks/menu";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import ScrollBtn from "../components/Buttons/scrollBtn";
import Cookies from "../components/Cookies/cookies";

const BaseLayout = ({ location, children, pageContext: { locale } }) => {
  const { changeLocale } = useLocale();
  const {
    openedMenu,
    toggleMenu,
    openedLang,
    toggleLang,
    openedSearch,
    toggleSearch,
    resultSearch,
  } = useMenu();
  useEffect(() => {
    changeLocale(locale);
  });

  useEffect(() => {
    if (openedMenu) toggleMenu(false);
    if (openedLang) toggleLang(false);
    if (openedSearch) toggleSearch(false);
  }, [location.href]);

  useEffect(() => {
    if (openedSearch && location.href.includes("search")) toggleSearch(false);
  }, [resultSearch]);

  return (
    <>
      <ScrollBtn />
      <div className="base__layout">
        <div className="top__content">
          <Header />
          {children}
        </div>
        <Footer />
      </div>
      <Cookies />
    </>
  );
};

export { BaseLayout };
