import React from "react";
import { Link } from "gatsby";
import { useLocale } from "../hooks/locale";
import locales from "../../config/i18n";

const LocalizedLink = ({ to, ...props }) => {
  const { locale } = useLocale();

  const isIndex = to === `/`;
  let path = to;

  if (props.type === "career") {
    if (locale === "pl") path = `/praca${path}`;
    else if (locale === "en") {
      path = `/career${path}`;
    } else if (locale === "de") {
      path = `/career${path}`;
    }
  } else if (props.type === "case study") {
    path = `/case-studies${path}`;
  } else if (props.type === "blog") {
    path = `/en/blog/${props.category}${path}`;
  }

  if (path !== "/blog") {
    path = locales[locale].default
      ? path
      : `/${locales[locale].path}${isIndex ? `` : `${path}`}`;
  } else {
    path = `/en${path}`;
  }
  return <Link {...props} to={path}></Link>;
};

export default LocalizedLink;
