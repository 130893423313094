import React from "react";
import Nav from "./nav";
import Logo from "./Logo/logo";
import useOtherLocations from "../useOtherLocations";

const Navbar = () => {
  const items = useOtherLocations();
  const matchItem = items.filter((item) => item.name === "pricing")[0];

  return (
    <>
      <Logo />
      <Nav matchItem={matchItem} />
    </>
  );
};

export default Navbar;
