import { useStaticQuery, graphql } from "gatsby";
import { useLocale } from "../hooks/locale";

function useOtherLocations() {
  const { locale } = useLocale();

  const { rawData } = useStaticQuery(query);
  const simplified = rawData.edges.map((item) => {
    return {
      name: item.node.name,
      otherItems: item.node.translations.otherItems,
    };
  });

  const matchItems = simplified.filter((lang) => lang.name === locale)[0];

  return matchItems.otherItems;
  // return menuItems;
}

export default useOtherLocations;

const query = graphql`
  query useOtherLocations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "menu" } }) {
      edges {
        node {
          name
          translations: childMenuJson {
            otherItems {
              link
              name
              title
              exclude
            }
          }
        }
      }
    }
  }
`;
