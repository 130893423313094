import React from "react";
import useMenu from "../useMenu";
import NavLinks from "../Navigation/navLinks";

const Navigation = () => {
  const menuItems = useMenu();
  return (
    <ul className="footer__list">
      {menuItems.map((menu, index) => (
        <NavLinks
          link={menu.link}
          exclude={menu.exclude}
          name={menu.name}
          key={index}
        />
      ))}
    </ul>
  );
};

export default Navigation;
