import React, { useState, useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useMenu } from "../../../hooks/menu";
import useTranslations from "../../useTranslations";
import useFlags from "../../useFlags";
import config from "../../../../config/config";

const getEndpoint = (query) => {
  const { cx, path } = config.search.api;

  return (
    path +
    "?key=" +
    process.env.REACT_APP_API_KEY_SEARCH +
    "&cx=" +
    cx +
    "&q=" +
    query
  );
};

async function get(endpoint, options = {}) {
  return fetch(endpoint, options)
    .then((response) => {
      return response.ok
        ? response.json()
        : Promise.reject(response.statusText);
    })
    .then((data) => {
      return data;
    });
}

const Search = () => {
  const { show_search_window } = useFlags();
  const { translate } = useStaticQuery(query);
  const { enter_search_text, something_went_wrong } =
    useTranslations(translate);
  const { setSearch, openedSearch, toggleSearch } = useMenu();
  const [value, setValue] = useState("");
  const [isLoad, setLoad] = useState(false);
  const [isError, setError] = useState(false);

  const openWindow = () => {
    if (show_search_window) {
      setValue("");
      setLoad(false);
      setError(false);
      toggleSearch(true);
    }
  };

  const closeWindow = () => {
    toggleSearch(false);
    setValue("");
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSearch = async (event) => {
    if (event.key === "Enter" && openedSearch && value !== "") {
      setLoad(true);
      search(event)
        .then((result) => {
          setSearch(result);
          navigate("/search");
        })
        .catch((err) => {
          setLoad(false);
          setError(true);
        });
    }
  };

  const search = (evt) => {
    const query = evt.target.value;
    const endpoint = getEndpoint(query);
    return new Promise((resolve, reject) => {
      get(endpoint).then(
        (search) => {
          resolve(search);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  useEffect(() => {
    if (openedSearch) document.getElementById("search-input").focus();
  });

  return (
    <div className="header__search">
      <button className="header__search-btn" onClick={openWindow}>
        <FontAwesomeIcon className="header__search-icon" icon={faSearch} />
      </button>
      {show_search_window && (
        <div
          className="search-window"
          style={{
            display: openedSearch ? "block" : "none",
            backgroundColor: openedSearch ? "#611154" : "transparent",
          }}
        >
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => null}
            className="search-window_close"
            onClick={closeWindow}
          >
            x
          </span>
          <p className="search-window__command">{enter_search_text}</p>
          <div className="search-window__text-area">
            <input
              id="search-input"
              onKeyDown={handleSearch}
              type="text"
              value={value}
              onChange={handleChange}
              className="search-window__text-area_in"
              spellCheck={false}
            />
          </div>
          <div className="search-window__info">
            {isLoad && <FontAwesomeIcon icon={faSpinner} spin />}
            {isError && <p>{something_went_wrong}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;

const query = graphql`
  query SearchIndexQuery {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            enter_search_text
            something_went_wrong
          }
        }
      }
    }
  }
`;
