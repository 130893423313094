import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import scrollToY from "../../functions/functions";

const ScrollBtn = () => {
  const [isDisplay, setDisplay] = useState(false);

  function handleScroll() {
    const height = window.innerHeight * 1.5 + 50;

    if (
      document.body.scrollTop > height ||
      document.documentElement.scrollTop > height
    ) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }

  const handleClick = () => {
    const scrollTargetY = 0; // the target scrolly property of the window
    const speed = 5000; // time in pixels per second
    const easing = "easeInOutQuint"; // easing equation to use
    scrollToY(scrollTargetY, speed, easing);
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleScroll);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      role="button"
      id="return-to-top"
      style={{ display: isDisplay ? "block" : "none" }}
      onClick={handleClick}
      tabIndex={0}
      onKeyUp={() => {
        return null;
      }}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </div>
  );
};

export default ScrollBtn;
