import { useStaticQuery, graphql } from "gatsby";
import { useLocale } from "../hooks/locale";

function useMenu() {
  const { locale } = useLocale();

  const { rawData } = useStaticQuery(query);
  const simplified = rawData.edges.map((item) => {
    return {
      name: item.node.name,
      menuItems: item.node.translations.menuItems,
    };
  });

  const { menuItems } = simplified.filter((lang) => lang.name === locale)[0];

  return menuItems;
}

export default useMenu;

const query = graphql`
  query useMenu {
    rawData: allFile(filter: { sourceInstanceName: { eq: "menu" } }) {
      edges {
        node {
          name
          translations: childMenuJson {
            menuItems {
              link
              name
              exclude
            }
          }
        }
      }
    }
  }
`;
