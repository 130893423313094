import React, { useEffect, useState } from "react";
import useMenu from "../useMenu";
import NavLinks from "./navLinks";
import Search from "./Search/search";
import Languages from "./Languages/languages";
import NavbarToggle from "./navbarToggle";
import LocalizedLink from "../LocalizedLink";
import { useMenu as toggleMenu } from "../../hooks/menu";

const Nav = ({ matchItem }) => {
  const menuItems = useMenu();
  const [isHiden, setHiden] = useState(true);
  const { openedMenu } = toggleMenu();

  const navLinks = menuItems.map((menu, index) => (
    <NavLinks
      link={menu.link}
      name={menu.name}
      exclude={menu.exclude}
      key={index}
      className="header__a "
    />
  ));

  const btnPricing = (
    <LocalizedLink
      to={matchItem.link}
      activeClassName="active"
      aria-label={matchItem.title}
      className={`btn btn-style-1-small`}
    >
      <span className="f-light">{matchItem.title.toUpperCase()}</span>
    </LocalizedLink>
  );

  const handleResize = () => {
    if (1069 >= window.innerWidth) setHiden(true);
    else setHiden(false);
  };

  useEffect(() => {
    setHiden(1069 >= window.innerWidth);
    function watchResize() {
      window.addEventListener("resize", handleResize);
    }
    watchResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {isHiden && (
        <div className="header__right">
          <Languages /> <Search /> <NavbarToggle />
        </div>
      )}
      {!isHiden && (
        <ul className="header__nav">
          {navLinks}
          <Search /> <Languages />
          {!matchItem.exclude && (
            <div className="columns header__btn btn__header__a">
              {btnPricing}
            </div>
          )}
        </ul>
      )}
      {isHiden && (
        <ul
          className={`header__nav  ${openedMenu && isHiden ? "mobileNav" : ""}`}
        >
          {navLinks}
          {!matchItem.exclude && (
            <div className="columns header__btn hide-s header__a">
              {btnPricing}
            </div>
          )}
        </ul>
      )}
    </>
  );
};

export default Nav;
