import React, { useState, useEffect } from "react";
import useTranslations from "../useTranslations";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import useOtherLocations from "../useOtherLocations";
import LocalizedLink from "../LocalizedLink";

const Cookies = () => {
  const { translate } = useStaticQuery(query);
  const { close, privacy_policy } = useTranslations(translate);
  const items = useOtherLocations();
  const [display, setDisplay] = useState(false);
  const [accept, setAccept] = useState(getCookieConsentValue());
  const matchItem = items.filter((item) => item.name === "privacy_policy")[0];

  useEffect(() => {
    let timer = getCookieConsentValue()
      ? null
      : setTimeout(() => setDisplay(true), 0);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <CookieConsent
      buttonText={close}
      location="bottom"
      buttonClasses="cc-btn"
      hideOnAccept={false}
      onAccept={() => setAccept(true)}
      containerClasses={`cc-banner ${display ? "cc-window" : ""} ${
        accept ? "cc-invisible" : ""
      }`}
      ariaAcceptLabel="dismiss cookie message"
      extraCookieOptions={{ domain: "" }}
      style={{ background: "rgb(0, 0, 0)", color: "rgb(255, 255, 255)" }}
    >
      {privacy_policy}
      <LocalizedLink
        to={matchItem.link}
        className="cc-link"
        aria-label="learn more about cookies"
      >
        {matchItem.title}
      </LocalizedLink>
    </CookieConsent>
  );
};

export default Cookies;

const query = graphql`
  query Cookies {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            close
            privacy_policy
          }
        }
      }
    }
  }
`;
