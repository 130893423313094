import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slogan from "./slogan";
import LocalizedLink from "../../LocalizedLink";

const Logo = () => {
  const { logo } = useStaticQuery(query);
  const img = getImage(logo);

  return (
    <div className="header__logo-box">
      <LocalizedLink to="/" title="home" aria-label="home">
        <div className="header__logo-img">
          <GatsbyImage image={img} alt="logo-rax" className="img-fluid" />
        </div>
        <Slogan />
      </LocalizedLink>
    </div>
  );
};

export default Logo;

const query = graphql`
  query navLogo {
    logo: file(relativePath: { eq: "logo_rax.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;
