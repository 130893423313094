import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => {
  return (
    <StaticImage
      src={"../../images/logo_rax_inv.png"}
      alt="logo"
      imgClassName="footer__logo-img"
    />
  );
};

export default Logo;
