// http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
const requestAnimFrame = (function () {
  if (typeof window !== "undefined") {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  }
})();

function scrollToY(scrollTargetY, speed, easing) {
  // scrollTargetY: the target scrollY property of the window
  // speed: time in pixels per second
  // easing: easing equation to use

  const scrollY = window.scrollY || document.documentElement.scrollTop;
  let currentTime = 0;

  scrollTargetY = scrollTargetY || 0;
  speed = speed || 2000;
  easing = easing || "easeOutSine";

  // min time .1, max time .8 seconds
  const time = Math.max(
    0.1,
    Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
  );

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  const easingEquations = {
    easeOutSine: function (pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine: function (pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1);
    },
    easeInOutQuint: function (pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow(pos - 2, 5) + 2);
    },
  };

  // add animation loop
  const tick = () => {
    currentTime += 20 / 60;

    const p = currentTime / time;
    const t = easingEquations[easing](p);

    if (p < 1) {
      requestAnimFrame(tick);

      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
    } else {
      console.log("scroll done");
      window.scrollTo(0, scrollTargetY);
    }
  };

  // call it once to get started
  tick();
}

function dateFormat(date) {
  let d;
  try {
    d = new Date(date);
  } catch (error) {
    console.log(error);
    return null;
  }
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${day}.${month}.${year}`;
}

// function getChildren(items) {
//   const elements = props.items.map((item, index) => {
//     const spaceBefore = index - 1 < 0 ? "" : " ";
//     const spaceAfter = index + 1 === props.items.length ? "" : " ";

//     return item.className === "" ? (
//       spaceBefore + item.text + spaceAfter
//     ) : (
//       <span className="important" ref={important}>
//         {item.text}
//       </span>
//     );
//   });
//   return elements;
// }

export { scrollToY as default, dateFormat };
