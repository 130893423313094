import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "./src/styles/global.css";
import "./src/styles/stats.css";
import "./src/styles/navigation.css";
import "./src/styles/odometer-theme-default.css";

import CustomLayout from "./wrapPageElement";

export const wrapPageElement = CustomLayout;
