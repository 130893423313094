//each flag hide or display each use specified component (for example. Setting the show_offer value to false will make the component Offer has been hidden from index and service page)

module.exports = {
  show_offer: false,
  show_portfolio: false,
  show_project_example: true,
  show_service: true,
  show_stats: true,
  show_map: true,
  show_clients: true,
  show_contact: true,
  show_details_company: true,
  show_team: false,
  show_case_studies_shuffle: false,
  show_search_window: true,
  show_maze: true,
  show_typewriter: true,
  show_job_offers: false,
};
